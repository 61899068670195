import styled from "styled-components";

export const StyledOverlay = styled.div`
  position: relative;
  width: 100%;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
`;
