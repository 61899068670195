import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import TagManager from "react-gtm-module";

export const turnstileSiteKey = process.env.REACT_APP_LOC
  ? "1x00000000000000000000AA"
  : "0x4AAAAAAAypW8QPnLK_LtKI";

TagManager.initialize({ gtmId: "GTM-W2VT763B" });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<App />);
