import { useEffect, useState } from "react";
import { useLogs } from "../../providers/LogProvider";

import { StatsType } from "../../providers/types";
import { Flex, Typography, Select } from "antd";
import { parseDataUnits } from "../../utils/parseDataUnits";
import { RenderChart } from "./RenderChart";
import React from "react";
import { StyledUsage, UsageDisclaimer } from "./styled";
const { Text, Title } = Typography;

export type ParsedStatsType = StatsType & {
  label: string;
};

export const Stats = () => {
  const { getStats, apps, stats, statsMeta, planDetails } = useLogs();
  const [activeChart, setActiveChart] = React.useState<string>("all");
  const [parsedStats, setParsedStats] = React.useState<ParsedStatsType[]>([]);
  const [parsedStatsWithUnits, setParsedStatsWithUnits] = useState<
    Record<string, ParsedStatsType>
  >({});

  useEffect(() => {
    if (stats.length === 0) {
      getStats();
    }
  }, [stats]);

  useEffect(() => {
    if (stats.length && apps.length) {
      const res: Record<string, ParsedStatsType> = {
        [apps[0].id]: {
          id: apps[0].id,
          backgroundColor: "#fff",
          data: [],
          label: apps[0].name,
        },
      };

      const result = stats.map((dataset) => {
        const label =
          apps.find((app) => app.id === dataset.id)?.name || dataset.id;
        res[dataset.id] = {
          ...dataset,
          data: dataset.data.map((el) => ({
            ...el,
            totalBytes: parseDataUnits(
              el.totalBytes,
              statsMeta[dataset.id].unit,
            ).value,
          })),
          label,
        };

        return {
          ...dataset,
          data: dataset.data.map((el) => ({
            ...el,
            totalBytes: parseDataUnits(el.totalBytes, "MB").value,
          })),
          label,
        };
      });

      setParsedStatsWithUnits(res);
      setParsedStats(result);
    }
  }, [stats, apps]);

  const displayTotalUsage = () => {
    let total = 0;

    Object.values(statsMeta).forEach((el) => {
      total += el.totalBytes;
    });

    const totalUsage = parseDataUnits(total);
    return `${totalUsage.value.toFixed(2)} ${totalUsage.unit}`;
  };

  const displayAppUsage = (appId: string) => {
    if (statsMeta[appId] === undefined) return "0 KB";
    const totalUsage = parseDataUnits(statsMeta[appId].totalBytes);
    return `${totalUsage.value.toFixed(2)} ${totalUsage.unit}`;
  };

  return (
    <div>
      <Flex vertical>
        <Text type="secondary">Change view</Text>
        <Select
          placeholder="Select App"
          style={{ width: 180, marginTop: 10 }}
          defaultValue={activeChart}
          onChange={(option) => setActiveChart(option)}
        >
          {apps.map((el) => (
            <Select.Option key={el.id} value={el.id}>
              {el.name}
            </Select.Option>
          ))}
          <Select.Option key={"all"} value="all">
            all apps
          </Select.Option>
        </Select>
      </Flex>
      <Flex vertical align="center" justify="center" style={{ marginTop: 20 }}>
        <Title level={4}>Your data usage in current period</Title>
        {planDetails && (
          <>
            <Flex justify="center" style={{ width: "100%", color: "white" }}>
              <StyledUsage>{`Currently used: ${(
                planDetails.usageMb / 1024
              ).toFixed(2)} GB`}</StyledUsage>
              <StyledUsage withoutBorder>{`Monthly limit: ${
                planDetails.limitMb / 1024
              } GB`}</StyledUsage>
            </Flex>
            <UsageDisclaimer>
              Your quota will renew on the 1st day of each month.
            </UsageDisclaimer>
          </>
        )}

        {activeChart !== "all" && (
          <Title level={5}>
            {`${
              apps.find((app) => app.id === activeChart)?.name || "App"
            } usage:
            ${displayAppUsage(activeChart)} / ${displayTotalUsage()}`}
          </Title>
        )}
      </Flex>

      <RenderChart
        key={activeChart}
        unit={
          activeChart === "all" || stats.length === 0
            ? "MB"
            : statsMeta[activeChart]?.unit
        }
        data={
          activeChart === "all" || !parsedStatsWithUnits[activeChart]
            ? parsedStats
            : [parsedStatsWithUnits[activeChart]]
        }
      />
    </div>
  );
};

export default Stats;
