import { Flex, Tag } from "antd";
import { useLogs } from "../../providers/LogProvider";
import { colors } from "./colors";
import dayjs from "dayjs";
import { timeFormatSec } from "../../config/const";

export const ActiveFilters = () => {
  const { fromDate, filters, setFilters, liveTail } = useLogs();

  const removeFilter = (key: string) => {
    if (!filters) return;
    const activeFilters = { ...filters };
    // eslint-disable-next-line
    // @ts-ignore
    delete activeFilters[key];
    setFilters(activeFilters);
  };

  const removeTimeFilter = (key: string) => {
    if (!filters) return;
    const activeFilters = { ...filters };
    // eslint-disable-next-line
    // @ts-ignore
    delete activeFilters[key];
    const now = new Date();

    setFilters({
      ...activeFilters,
      dateFrom: now.getTime().toString(),
      dateTo: now.getTime().toString(),
    });
  };

  if (!filters) return null;
  return (
    <Flex justify="flex-start" align="center">
      <div></div>
      {Object.entries(filters).map(([key, value]) => {
        if (
          key === "direction" ||
          key === "activeApp" ||
          key === "text" ||
          key === "worker"
        )
          return null;
        if ((key === "dateTo" || key === "relativeTime") && liveTail)
          return null;
        if (key === "dateFrom" && liveTail) {
          return (
            <Tag
              style={{ fontSize: "14px", padding: "4px 10px" }}
              color={colors[key]}
            >
              {`From: ${dayjs(new Date(liveTail)).format(timeFormatSec)}`}
            </Tag>
          );
        }
        if (key === "dateFrom" && fromDate) {
          return (
            <Tag
              style={{ fontSize: "14px", padding: "4px 10px" }}
              color={colors[key]}
            >
              {`From: ${dayjs(new Date(parseInt(fromDate))).format(
                timeFormatSec,
              )}`}
            </Tag>
          );
        }

        if (key === "timestamp") {
          return (
            <Tag
              style={{ fontSize: "14px", padding: "4px 10px" }}
              color={colors[key]}
              closable
              onClose={() => removeTimeFilter("timestamp")}
            >
              {`Timestamp: ${dayjs(new Date(parseInt(filters.dateFrom))).format(
                timeFormatSec,
              )}`}
            </Tag>
          );
        }
        if (
          (key === "dateFrom" || key === "dateTo") &&
          (filters.dateTo || filters.dateFrom)
        ) {
          if (filters.dateFrom === filters.dateTo && !filters.text) {
            return null;
          }

          return (
            <Tag
              style={{ fontSize: "14px", padding: "4px 10px" }}
              color={colors[key]}
            >
              {`${key === "dateFrom" ? "From" : "To"}: ${dayjs(
                new Date(parseInt(filters[key])),
              ).format(timeFormatSec)}`}
            </Tag>
          );
        }

        if (key === "relativeTime") {
          return (
            <Tag
              style={{ fontSize: "14px", padding: "4px 10px" }}
              color={colors[key]}
              closable
              onClose={() => removeTimeFilter("relativeTime")}
            >
              {`Last 
              ${filters[key]?.split("#")[0]}${filters[key]?.split("#")[1]}`}
            </Tag>
          );
        }

        return (
          <Tag
            style={{ fontSize: "14px", padding: "4px 10px" }}
            color={colors[key]}
            closable
            onClose={() => {
              removeFilter(key);
            }}
          >
            {value}
          </Tag>
        );
      })}
    </Flex>
  );
};
