import { Button, Flex, Modal, Typography } from "antd";
import { useLogs } from "../../providers/LogProvider";
import { useAuth } from "../../providers/AuthProvider";

export const PremiumPopup = () => {
  const { showPremiumPopup, setShowPremiumPopup } = useLogs();
  const { mainApp } = useAuth();
  return (
    <Modal
      open={showPremiumPopup}
      onCancel={() => setShowPremiumPopup(false)}
      footer={null}
    >
      <Flex
        vertical
        align="center"
        style={{ maxWidth: 400, margin: "0 auto", textAlign: "center" }}
      >
        <h1 style={{ textAlign: "center" }}>Upgrade needed</h1>
        <Typography.Text type="secondary">
          This feature is not included in your current plan.
        </Typography.Text>
        <Typography.Text type="secondary" style={{ marginBottom: 20 }}>
          To access this and other premium features, please upgrade to a higher
          plan.
        </Typography.Text>
        <a
          href={
            mainApp
              ? `https://dashboard.heroku.com/apps/${mainApp}/resources`
              : "https://elements.heroku.com/addons/logabyss"
          }
          target="_blank"
        >
          <Button type="primary" style={{ marginBottom: 20 }}>
            Upgrade
          </Button>
        </a>
      </Flex>
    </Modal>
  );
};
