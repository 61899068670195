import { Button, Modal, Row, Col, Typography, Flex } from "antd";
import { useLogs } from "../../providers/LogProvider";
import { useEffect, useState } from "react";
import { GenerateExportsRequestType } from "../../api/types";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { timeFormatMillSec } from "../../config/const";

type PopupProps = {
  handleOpen: (state: boolean) => void;
};

export const GeneratePopup = ({ handleOpen }: PopupProps) => {
  const { apps, logsMeta, generateLogsExport, filters } = useLogs();
  const [values, setValues] = useState<GenerateExportsRequestType | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setValues(getInitialValues());
  }, [filters]);

  const getInitialValues = () => {
    const initialValues: GenerateExportsRequestType = {
      appId: "",
      dateFrom: "",
      dateTo: "",
      text: "",
      workers: [],
    };

    if (filters && logsMeta) {
      initialValues.appId = filters.activeApp;
      initialValues.dateFrom = logsMeta.dateFrom;
      initialValues.dateTo = logsMeta.dateTo;

      if (filters.text) {
        initialValues.text = filters.text;
      }

      if (filters.worker) {
        initialValues.workers = filters.worker;
      }
    }

    return initialValues;
  };

  if (!values) {
    return null;
  }

  const handleSubmit = () => {
    generateLogsExport(values);
    navigate("/exports");
  };

  return (
    <Modal
      title="Export logs summary"
      open={true}
      onCancel={() => handleOpen(false)}
      onOk={() => (values ? generateLogsExport(values) : null)}
      footer={[
        <Button key="back" onClick={() => handleOpen(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Export
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col span={8}>App name:</Col>
        <Col span={16}>{apps.find((app) => app.id === values.appId)?.name}</Col>
        <Col span={8}>Searched text phrase:</Col>
        <Col span={16}>{values.text || "-"}</Col>
        <Col span={8}>Workers:</Col>
        <Col span={16}>
          {values.workers.length > 0
            ? values.workers.map((worker) => worker)
            : "-"}
        </Col>
        {logsMeta && (
          <>
            <Col span={8}>Date from:</Col>
            <Col span={16}>
              {dayjs(logsMeta.dateFrom).format(timeFormatMillSec)}
            </Col>

            <Col span={8}>Date to:</Col>
            <Col span={16}>
              {dayjs(logsMeta.dateTo).format(timeFormatMillSec)}
            </Col>

            <Col span={8}>Rows count:</Col>
            <Col span={16}>{logsMeta.total}</Col>
          </>
        )}
      </Row>
      {logsMeta && logsMeta.total > 10000 && (
        <Flex style={{ marginTop: "20px" }}>
          <Typography.Text type="secondary" style={{ textAlign: "center" }}>
            The maximum size for log file exports is 128 MB. Exports exceeding
            this size will be truncated. To ensure a complete export, please
            adjust your query to limit the records accordingly.
          </Typography.Text>
        </Flex>
      )}
    </Modal>
  );
};
