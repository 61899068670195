import { useState } from "react";
import { useLogs } from "../../providers/LogProvider";
import { Button } from "antd";
import { GeneratePopup } from "../../scenes/exports/generatePopup";
import { StarOutlined } from "@ant-design/icons";
import TagManager from "react-gtm-module";

export const ExportsButton = () => {
  const { logsMeta, filters, getLogs, planDetails, setShowPremiumPopup } =
    useLogs();
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const handlePopupOpen = () => {
    setPopupVisible(true);

    if (filters && logsMeta) {
      getLogs({
        appId: filters.activeApp,
        text: filters.text || null,
        dateFrom: new Date(parseInt(filters.dateFrom)).toISOString(),
        dateTo: new Date(parseInt(filters.dateTo)).toISOString(),
        direction: filters.direction,
        worker: filters.worker || [],
        countQuery: true,
      });
    }
  };

  if (!planDetails) {
    return null;
  }

  const handleClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "elementClick",
        event_info: {
          type: "button",
          name: "Export logs",
          isPremium: planDetails.paidPlan,
          hasExports: planDetails.hasExports,
          planName: planDetails.name,
        },
      },
    });
    if (planDetails.paidPlan && planDetails.hasExports) {
      handlePopupOpen();
    } else {
      setShowPremiumPopup(true);
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={handleClick}
        style={{ marginLeft: "15px" }}
      >
        Export logs
        {!planDetails.paidPlan && <StarOutlined />}
      </Button>
      {popupVisible && <GeneratePopup handleOpen={setPopupVisible} />}
    </>
  );
};
