import dayjs from "dayjs";
import {
  ParsedStatsType,
  SingleStatsType,
  StatsMetaType,
  StatsResponseType,
  StatsType,
} from "../providers/types";
import { parseDataUnits } from "./parseDataUnits";
import { backgroundColors } from "../config/const";

export const parseStats = (rawStats: StatsResponseType[]): ParsedStatsType => {
  const stats = rawStats;

  const beginingOfPeriod = dayjs().startOf("month");
  const endOfPeriod = dayjs().endOf("month");

  if (stats.length === 0) {
    return {
      data: [],
      meta: {},
    };
  }

  const meta: {
    [key: string]: {
      totalBytes: number;
      unit: string;
      lowestValue: number;
    };
  } = {};
  // parse stats so we have object each appId is seperate key,
  // and each key has array of stats for each day
  const result = stats.reduce(
    (acc, curr) => {
      const appId = curr.appId;
      const totalBytes = parseInt(curr.totalBytes);

      if (!acc[appId]) {
        meta[appId] = {
          unit: "B",
          totalBytes: 0,
          lowestValue: totalBytes,
        };
        acc[appId] = [];
      }

      if (totalBytes < meta[appId].lowestValue) {
        meta[appId].lowestValue = totalBytes;
      }

      acc[appId].push({
        ...curr,
        totalBytes,
        date: dayjs(curr.date).format("DD MMM"),
      });
      meta[appId].totalBytes += totalBytes;
      return acc;
    },
    {} as Record<string, SingleStatsType[]>,
  );

  const datasets = [];

  const metaResult: StatsMetaType = {};
  for (const appId in result) {
    let data = result[appId];
    let lowestDate = dayjs(data[0].date);
    let highestDate = dayjs(data[0].date);

    data.forEach((el) => {
      if (dayjs(el.date).isBefore(dayjs(lowestDate))) {
        lowestDate = dayjs(el.date);
      }
      if (dayjs(el.date).isAfter(dayjs(highestDate))) {
        highestDate = dayjs(el.date);
      }
    });

    if (lowestDate.format("DD") !== beginingOfPeriod.format("DD")) {
      const startDay = beginingOfPeriod.format("DD");
      const startLogs = lowestDate.format("DD");
      const difference = parseInt(startLogs) - parseInt(startDay);

      const missingsDays = [];
      for (let i = 0; i < difference; i++) {
        missingsDays.push({
          date: beginingOfPeriod.add(i, "day").format("DD MMM"),
          appId,
          appName: data[0].appName,
          totalBytes: 0,
        });
      }

      data = [...missingsDays, ...data];
    }
    if (highestDate.format("DD") !== endOfPeriod.format("DD")) {
      const endDay = endOfPeriod.format("DD");
      const endLogs = highestDate.format("DD");

      const difference = parseInt(endDay) - parseInt(endLogs);

      const missingsDays = [];
      for (let i = 1; i <= difference; i++) {
        missingsDays.push({
          date: highestDate.add(i, "day").format("DD MMM"),
          appId,
          appName: data[0].appName,
          totalBytes: 0,
        });
      }

      data = [...data, ...missingsDays];
    }

    const dataset: StatsType = {
      id: appId,
      data,
      backgroundColor: backgroundColors[datasets.length],
    };
    datasets.push(dataset);

    metaResult[appId] = {
      ...meta[appId],
      totalBytes: meta[appId].totalBytes,
      unit: parseDataUnits(meta[appId].lowestValue).unit,
    };
  }

  return {
    data: datasets,
    meta: metaResult,
  };
};
