import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ConfigProvider, theme } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from "./protectedRoute";

import Login from "./scenes/login";
import { AuthProvider } from "./providers/AuthProvider";
import { Dashboard } from "./scenes/dashboard";
import { LogProvider } from "./providers/LogProvider";
import { Stats } from "./scenes/stats";
import { Alerts } from "./scenes/alerts";
import { AlertsProvider } from "./providers/AlertProvider";
import Exports from "./scenes/exports";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <LogProvider>
          <AlertsProvider>
            <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/alerts"
                  element={
                    <ProtectedRoute>
                      <Alerts />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/usage"
                  element={
                    <ProtectedRoute>
                      <Stats />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/exports"
                  element={
                    <ProtectedRoute>
                      <Exports />
                    </ProtectedRoute>
                  }
                />

                <Route path="/login" element={<Login />} />
                <Route
                  path="/stats"
                  element={<Navigate to="/usage" replace />}
                />
              </Routes>
              <ToastContainer />
            </ConfigProvider>
          </AlertsProvider>
        </LogProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
