import { Flex } from "antd";
import { BarContainer, StyledMenuButton } from "./styled";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "../ProgressBar";

type MenuButtonProps = {
  isCollapsed: boolean;
  isActive: boolean;
  children: React.ReactNode;
  label: string;
};

export const MenuButton = ({
  isCollapsed,
  isActive,
  children,
  label,
}: MenuButtonProps) => {
  const navigate = useNavigate();
  return (
    <StyledMenuButton
      isCollapsed={isCollapsed}
      isActive={isActive}
      onClick={() => navigate("/usage")}
    >
      <Flex align="center" style={{ fontSize: "16px" }}>
        <span style={{ paddingLeft: isCollapsed ? "5px" : "0" }}>
          {children}
        </span>
        {!isCollapsed && <span style={{ marginLeft: "10px" }}>{label}</span>}
      </Flex>
      <BarContainer>
        <ProgressBar />
      </BarContainer>
    </StyledMenuButton>
  );
};
