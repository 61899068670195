import { useEffect, useState } from "react";

import { Flex, Typography, Table, Tag, Button } from "antd";
import { SyncOutlined, DownloadOutlined } from "@ant-design/icons";
import { useLogs } from "../../providers/LogProvider";

import { LogExportType } from "../../providers/types";
import dayjs from "dayjs";
import { timeFormatMillSec, timeFormatSec } from "../../config/const";
import { useAuth } from "../../providers/AuthProvider";
import { Key } from "antd/es/table/interface";

const { Title } = Typography;

export const Exports = () => {
  const { getExportedLogs, exportedLogs, apps, planDetails } = useLogs();
  const { mainApp } = useAuth();
  const [columns, setColumns] = useState<Record<string, unknown>[]>([]);

  useEffect(() => {
    if (exportedLogs === null) {
      getExportedLogs();
    }
  }, [exportedLogs]);

  useEffect(() => {
    const col = [
      {
        title: "Application",
        dataIndex: "appId",
        key: "appId",
        render: (appId: string) => {
          const app = apps.find((app) => app.id === appId);
          return app?.name;
        },
        filters: apps.map((app) => ({ text: app.name, value: app.id })),
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: Date) => {
          return dayjs(createdAt).format(timeFormatSec);
        },
        sorter: (a: LogExportType, b: LogExportType) =>
          dayjs(a.createdAt).diff(dayjs(b.createdAt)),
      },
      {
        title: "Text",
        dataIndex: "text",
        key: "text",
        render: (text: string) => {
          return text || "-";
        },
      },
      {
        title: "Date range",
        dataIndex: "dateRange",
        key: "dateRange",
        render: (_: string, row: LogExportType) => {
          return (
            <>
              <div>
                <span style={{ display: "inline-block", width: "40px" }}>
                  from:
                </span>
                {dayjs(row.dateFrom).format(timeFormatMillSec)}
              </div>
              <div>
                <span style={{ display: "inline-block", width: "40px" }}>
                  to:
                </span>
                {dayjs(row.dateTo).format(timeFormatMillSec)}
              </div>
            </>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status: string, row: LogExportType) => {
          if (dayjs() > dayjs(row.expirationDate)) {
            return <Tag color="gray">EXPIRED</Tag>;
          }
          if (status === "FAILED") {
            return <Tag color="red">{status}</Tag>;
          }
          if (status === "PENDING") {
            return <Tag color="yellow">{status}</Tag>;
          }
          if (status === "COMPLETED") {
            return <Tag color="green">{status}</Tag>;
          }
          return <Tag color="purple">{status}</Tag>;
        },
        filters: [
          {
            text: "COMPLETED",
            value: "COMPLETED",
          },
          {
            text: "PENDING",
            value: "PENDING",
          },
          {
            text: "FAILED",
            value: "FAILED",
          },
        ],
        onFilter: (value: boolean | Key, record: LogExportType) =>
          record.status === value,
      },

      {
        title: "Download",
        dataIndex: "downloadUrl",
        key: "downloadUrl",
        render: (downloadUrl: string, row: LogExportType) => {
          console.log(row.expirationDate, "exp");
          if (downloadUrl && dayjs() < dayjs(row.expirationDate)) {
            return (
              <a href={downloadUrl} download>
                <Button type="primary">
                  <DownloadOutlined />
                </Button>
              </a>
            );
          }
        },
      },
    ];
    if (apps.length === 1) {
      col.splice(0, 1);
    }
    setColumns(col);
  }, [apps]);

  return (
    <div>
      <Flex vertical align="center" justify="center" style={{ marginTop: 20 }}>
        <Title style={{ marginBottom: 20 }} level={4}>
          Exported logs
        </Title>

        {planDetails?.hasExports && (
          <Flex
            justify="flex-end"
            style={{ width: "100%", margin: "0 0 20px" }}
          >
            <Button onClick={getExportedLogs}>
              Refresh <SyncOutlined />
            </Button>
          </Flex>
        )}
        {planDetails && !planDetails.hasExports ? (
          <>
            <Typography.Text type="secondary" style={{ marginBottom: 20 }}>
              Log exports are not available in your current plan. Please upgrade
              to a higher plan to use this feature.
            </Typography.Text>
            <a
              href={
                mainApp
                  ? `https://dashboard.heroku.com/apps/${mainApp}/resources`
                  : "https://elements.heroku.com/addons/logabyss"
              }
              target="_blank"
            >
              <Button type="primary" style={{ marginBottom: 20 }}>
                Upgrade
              </Button>
            </a>
          </>
        ) : (
          <>
            {exportedLogs === null ||
              (exportedLogs?.length === 0 && (
                <>
                  <Typography.Text
                    type="secondary"
                    style={{ marginBottom: 20 }}
                  >
                    You do not have any exported logs yet. Create one to get
                    notified.
                  </Typography.Text>
                </>
              ))}
          </>
        )}

        <Table
          style={{ width: "100%" }}
          dataSource={exportedLogs || []}
          columns={columns}
          pagination={false}
          expandRowByClick
          onRow={(record) => ({
            style: {
              cursor:
                record.downloadUrl ||
                record.workers.length > 0 ||
                record.expirationDate
                  ? "pointer"
                  : "default",
            }, // Apply cursor style conditionally
          })}
          expandable={{
            rowExpandable: (record) =>
              !!(
                record.downloadUrl ||
                record.workers.length > 0 ||
                record.expirationDate
              ),
            expandedRowRender: (record) => {
              return (
                <Flex vertical style={{ paddingLeft: "45px" }}>
                  <div>
                    <Typography.Text strong style={{ width: "100px" }}>
                      Worker:
                    </Typography.Text>{" "}
                    {record.workers.length > 0
                      ? record.workers.map((el) => el).join(", ")
                      : "-"}
                  </div>

                  {record.expirationDate && (
                    <div>
                      <Typography.Text strong style={{ width: "100px" }}>
                        {dayjs() > dayjs(record.expirationDate)
                          ? "Expired at: "
                          : "Expires at: "}
                      </Typography.Text>
                      {dayjs(record.expirationDate).format(timeFormatSec)}
                    </div>
                  )}
                </Flex>
              );
            },
          }}
        />
      </Flex>
    </div>
  );
};

export default Exports;
