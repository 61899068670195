import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../config/api";
import { AuthContextType, LoginValues } from "./types";
import { jwtDecode } from "jwt-decode";

const auth = async (values: LoginValues) => {
  let token = null;
  try {
    const response = await axios.post(`${API_URL}/auth/login`, values);
    token = response.data.token;
  } catch (error) {
    toast.error("Failed to login");
    console.error(error);
  }

  return token;
};

const defaultAuthContext = {
  token: "",
  email: "",
  mainApp: "",
  onLogin: () => {},
  onLogout: () => {},
};
export const AuthContext = createContext<AuthContextType>(defaultAuthContext);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [mainApp, setMainApp] = useState(null);
  const location = useLocation();

  const validateToken = (token: string) => {
    if (!token) return false;
    try {
      const decodedToken = jwtDecode(token);
      if (
        !decodedToken ||
        !decodedToken?.exp ||
        decodedToken?.exp * 1000 < Date.now()
      ) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    let rawToken = null;
    let sso = false;
    if (location.search.includes("ssoToken=")) {
      rawToken = new URLSearchParams(location.search).get("ssoToken");
      sso = true;
    } else {
      rawToken = localStorage.getItem("token");
    }

    if (rawToken && validateToken(rawToken)) {
      setToken(rawToken);
      localStorage.setItem("token", rawToken);
      const decodedToken = jwtDecode(rawToken);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setEmail(decodedToken.email);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setMainApp(decodedToken.herokuContextApp);

      if (sso) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        localStorage.setItem("appId", decodedToken.herokuContextApp);
        navigate("/");
      } else {
        navigate({ pathname: location.pathname, search: location.search });
      }
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setEmail(decodedToken.email);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setMainApp(decodedToken.herokuContextApp);
      }
    }
  }, [token]);

  const handleLogin = async (values: LoginValues) => {
    const token = await auth(values);
    localStorage.setItem("token", token);
    setToken(token);
    navigate("/");
  };

  const handleLogout = () => {
    setToken("");
    setEmail("");
    localStorage.clear();
    navigate("/login");
  };

  const value = {
    token,
    email,
    mainApp,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
