import styled from "styled-components";

export const StyledMenuButton = styled.button<{
  isActive: boolean;
  isCollapsed: boolean;
}>`
  padding-inline: ${({ isCollapsed }) =>
    isCollapsed ? "calc(50% - 8px - 4px);" : "16px"};
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-inline: 4px;
  margin-block: 4px;
  width: calc(100% - 8px);
  height: 43px;
  line-height: 40px;
  background: transparent;
  border-radius: 8px;
  border: none;
  padding-left: 24px;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  color: rgba(255, 255, 255, 0.65);
  position: relative;

  &:hover {
    color: white;
  }

  //condtionally apply styles based on props isActive
    ${({ isActive }) =>
      isActive &&
      `
        color: white;
        background-color: #1668dc;
    `}
}
`;

export const BarContainer = styled.div`
  position: absolute;
  bottom: -3px;
  left: 50%;
  width: calc(100% - 34px);
  transform: translateX(-50%);
`;
