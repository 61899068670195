import { Box } from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
import { Flex, Form, Layout, Input, Typography, Button, Modal } from "antd";
import { useState } from "react";
import Turnstile from "react-turnstile";
import { turnstileSiteKey } from "../..";

const Login = () => {
  const { onLogin } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState("");

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <main className="content">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          sx={{ marginTop: "20px" }}
        >
          <Box mb="30px">
            <Typography.Title>LOGIN</Typography.Title>
            <Typography.Title level={5}>
              Welcome to LogAbyss app
            </Typography.Title>
          </Box>
        </Box>
        <Flex vertical justify="space-between" align="center">
          <Flex justify="center" style={{ marginTop: "30px" }}>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={(values) =>
                onLogin({ ...values, turnstileToken: token })
              }
              onFinishFailed={() => console.log("failed")}
              autoComplete="off"
            >
              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Flex justify="center">
                <Button
                  type="text"
                  style={{
                    marginBottom: 10,
                  }}
                  onClick={() => setIsModalOpen(true)}
                >
                  <span
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    Are you Heroku user?
                  </span>
                </Button>
              </Flex>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
              <Turnstile
                sitekey={turnstileSiteKey}
                fixedSize
                refreshExpired={"auto"}
                onVerify={(token) => {
                  setToken(token);
                }}
              />
            </Form>
          </Flex>
        </Flex>
      </main>
      <Modal
        title="Heroku login"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <p>
          Heroku users can login by clicking LogAbyss app that is listed in
          Installed add-ons on Heroku
        </p>
        <img style={{ width: "100%", height: "auto" }} src="./scr/addon.png" />
      </Modal>
    </Layout>
  );
};

export default Login;
