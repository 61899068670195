import axios from "axios";
import { API_URL } from "../config/api";
import { notification } from "antd";
import {
  GenerateExportsRequestType,
  GetHistoryRequestType,
  GetLogsRequestType,
  SendFormRequestType,
} from "./types";
// import { mockedStats } from "../mocks/graph";
// import { mockedApps } from "../mocks/apps";
// import { mockedLogs } from "../mocks/logs";

export const getApps = async (token: string) => {
  let response = null;
  try {
    response = await axios({
      method: "get",
      url: `${API_URL}/apps`,
      headers: { Authorization: `Bearer ${token}` },
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    notification.error({
      message: "Error",
      description: "Failed to fetch apps",
    });
    console.error(error);
  }

  return response?.data;
};

export const getLogs = async (token: string, payload: GetLogsRequestType) => {
  const { appId, text, dateFrom, dateTo, direction, worker, countQuery } =
    payload;
  let response = null;
  try {
    response = await axios({
      method: "post",
      url: `${API_URL}/search`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        appId,
        text,
        dateFrom,
        dateTo,
        direction,
        ...(countQuery && { countQuery }),
        ...(worker && { worker }),
      },
    });
  } catch (error) {
    console.error(error);
  }

  return response?.data;
};

export const getHistory = async (
  token: string,
  payload: GetHistoryRequestType,
) => {
  const { appId } = payload;
  let response = null;
  try {
    response = await axios({
      method: "get",
      url: `${API_URL}/search/history/${appId}`,
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error(error);
  }

  return response?.data;
};

export const getExports = async (token: string) => {
  let response = null;
  try {
    response = await axios({
      method: "get",
      url: `${API_URL}/exports`,
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error(error);
  }

  return response?.data;
};

export const generateExports = async (
  token: string,
  data: GenerateExportsRequestType,
) => {
  let response = null;
  try {
    response = await axios({
      method: "post",
      url: `${API_URL}/exports`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
  } catch (error) {
    notification.error({
      message: "Error",
      description: "Failed to schedule logs export",
    });

    console.error(error);
  }

  return response?.data;
};

export const getStats = async (token: string) => {
  let response = null;
  try {
    response = await axios({
      method: "get",
      url: `${API_URL}/stats/latest`,
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    notification.error({
      message: "Error",
      description: "Failed to fetch stats",
    });

    console.error(error);
  }

  return response?.data;
};

export const sendForm = async (token: string, data: SendFormRequestType) => {
  let response = null;
  try {
    response = await axios({
      method: "post",
      url: `${API_URL}/contact`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
  } catch (error) {
    notification.error({
      message: "Error",
      description: "Failed to fetch stats",
    });

    console.error(error);
  }

  return response?.data;
};

export const getPlan = async (token: string) => {
  let response = null;
  try {
    response = await axios({
      method: "get",
      url: `${API_URL}/plan`,
      headers: { Authorization: `Bearer ${token}` },
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    console.error(error);
  }

  return response?.data;
};
