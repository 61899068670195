import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useContext, useState, useRef, useEffect } from "react";
import {
  CalendarOutlined,
  UserOutlined,
  BarChartOutlined,
  WarningOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Flex, theme } from "antd";

const { Sider, Content } = Layout;

import { AuthContext, useAuth } from "../providers/AuthProvider";
import { jwtDecode } from "jwt-decode";
import { PlanNotifcation } from "../components/PlanNotifcation";
import { MenuButton } from "../components/MenuButton";
import { useLogs } from "../providers/LogProvider";
import { useAlerts } from "../providers/AlertProvider";
import { PremiumPopup } from "../components/PremiumPopup";
import TagManager from "react-gtm-module";

export type TokenType = {
  email: string;
  teamId: string;
  heroku: boolean;
  tawkHash: string;
};

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const tawkMessengerRef = useRef(null);
  const [collapsed, setCollapsed] = useState(false);
  const { token } = useAuth();
  const { onLogout } = useContext(AuthContext);
  const { clearState, getApps, apps, getPlanDetails, planDetails } = useLogs();
  const { resetAlertsState } = useAlerts();
  const location = useLocation();
  const navigate = useNavigate();
  const [isPlanFetched, setIsPlanFetched] = useState(false);

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    if (token) {
      if (!planDetails && !isPlanFetched) {
        setIsPlanFetched(true);
        getPlanDetails();
      }
      if (apps.length == 0) {
        getApps();
      }
    }
  }, [apps, planDetails, token, isPlanFetched]);

  useEffect(() => {
    if (isPlanFetched && planDetails) {
      TagManager.dataLayer({
        dataLayer: {
          event: "appLogin",
          event_info: {
            name: "User logged in",
            isPremium: planDetails.paidPlan,
            hasExports: planDetails.hasExports,
            planName: planDetails.name,
          },
        },
      });
    }
  }, [isPlanFetched, planDetails]);

  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  const onLoad = () => {
    const decodedToken: TokenType = jwtDecode(token);
    if (!tawkMessengerRef || !tawkMessengerRef.current || !decodedToken) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tawkMessengerRef.current.setAttributes(
      {
        name: `
        ${apps[0].name} | ${decodedToken.email}`,
        teamid: decodedToken.teamId,
        email: decodedToken.email,
        hash: decodedToken.tawkHash,
      },
      function (error: unknown) {
        console.error(error);
        // do something if error
      },
    );
  };

  const onVistorNameChange = (name: string) => {
    const decodedToken: TokenType = jwtDecode(token);
    if (!tawkMessengerRef || !tawkMessengerRef.current || !decodedToken) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tawkMessengerRef.current.setAttributes(
      {
        name: `${name} | ${decodedToken.email}`,
        hash: decodedToken.tawkHash,
      },
      function (error: unknown) {
        console.log(error, "error");
        console.error("error", error);
        // do something if error
      },
    );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <div>
        <Sider
          style={{
            height: "100vh",
            position: "fixed",
            transition: "all ease 0.4s",
          }}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Flex
            vertical
            justify="space-between"
            align="center"
            style={{ height: "100%" }}
          >
            <div>
              <Link to="/" target="_blank">
                <Flex
                  justify="center"
                  vertical
                  style={{
                    margin: "20px 0 20px",
                    color: "white",
                    width: collapsed ? "auto" : 150,
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      maxWidth: "45px",
                      margin: "0 auto",
                    }}
                    src="/loo.png"
                  />

                  {!collapsed && (
                    <img
                      style={{
                        margin: "10px auto 0",
                        width: "100%",
                        height: "auto",
                        maxWidth: "130px",
                      }}
                      src="/logotextnew.png"
                    />
                  )}
                </Flex>
              </Link>

              <Menu
                selectedKeys={[location.pathname]}
                theme="dark"
                mode="inline"
                items={[
                  {
                    key: "/",
                    icon: <CalendarOutlined />,
                    label: "Logs",
                    onClick: () => navigate("/"),
                  },
                  {
                    key: "/alerts",
                    icon: <WarningOutlined />,
                    label: "Alerts",
                    onClick: () => navigate("/alerts"),
                  },
                  {
                    key: "/exports",
                    icon: <ExportOutlined />,
                    label: "Exports",
                    onClick: () => navigate("/exports"),
                  },
                ]}
              />

              <MenuButton
                isActive={location.pathname === "/usage"}
                isCollapsed={collapsed}
                label="Usage"
              >
                <BarChartOutlined />
              </MenuButton>
            </div>
            <Menu
              theme="dark"
              mode="inline"
              items={[
                {
                  key: "3",
                  icon: <UserOutlined />,
                  label: "Logout",
                  onClick: () => [onLogout(), resetAlertsState(), clearState()],
                },
              ]}
            />
          </Flex>
        </Sider>
      </div>

      <Layout style={{ marginLeft: collapsed ? "80px" : "200px" }}>
        <PlanNotifcation />
        <Content
          style={{
            minHeight: "100vh",
            padding: "20px 15px",
            borderRadius: borderRadiusLG,
          }}
        >
          {children}
        </Content>
      </Layout>
      {apps.length > 0 && (
        <TawkMessengerReact
          onLoad={onLoad}
          propertyId="6564615f1db16644c55501be"
          widgetId="1hg81on6i"
          ref={tawkMessengerRef}
          onVisitorNameChanged={onVistorNameChange}
        />
      )}
      <PremiumPopup />
    </Layout>
  );
};
